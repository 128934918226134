import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const AttendanceStatusList = SuspenseWrapper(
  lazy(() => import("components/attendance/StatusList"))
);
const AttendanceTypeDataList = SuspenseWrapper(
  lazy(() => import("components/attendance/TypeDataList"))
);
const AttendanceMasterList = SuspenseWrapper(
  lazy(() => import("components/attendance/MasterList"))
);
const AttendanceSettingsList = SuspenseWrapper(
  lazy(() => import("components/attendance/SettingsList"))
);
const AttendanceManualEntryParams = SuspenseWrapper(
  lazy(() => import("components/attendance/manual-entry/ManualEntryParams"))
);
const AttendanceManualEntryEdit = SuspenseWrapper(
  lazy(() => import("components/attendance/manual-entry/ManualEntry"))
);
const AttendanceManualEntryView = SuspenseWrapper(
  lazy(() => import("components/attendance/manual-entry/ManualEntryView"))
);
const AttendanceManualEntryList = SuspenseWrapper(
  lazy(() => import("components/attendance/manual-entry/AttendanceList"))
);
const AttStudentReport = SuspenseWrapper(
  lazy(() => import("components/attendance/reports/StudentReport"))
);
const AttConsolidatedReport = SuspenseWrapper(
  lazy(() => import("components/attendance/reports/ConsolidatedReport"))
);

const ScholarDayMonthAtt = SuspenseWrapper(
  lazy(() => import("components/attendance/month-attendance/ScholarDayAtt"))
);

const AttendanceOverView = SuspenseWrapper(
  lazy(() => import("components/attendance/manual-entry/OverView"))
);

const ScholarSessionAtt = SuspenseWrapper(
  lazy(() => import("components/attendance/month-attendance/ScholarSessionAtt"))
);

const ScholarPeriodAtt = SuspenseWrapper(
  lazy(() => import("components/attendance/month-attendance/ScholarPeriodAtt"))
);

const AttendanceHolidayTypeList = SuspenseWrapper(
  lazy(() => import("components/attendance/AttendanceHolidayTypeList"))
);

const AttendanceHolidayList = SuspenseWrapper(
  lazy(() => import("components/attendance/AttendanceHolidayList"))
);

const AttendaceHolidayForm = SuspenseWrapper(
  lazy(() => import("components/attendance/AttendaceHolidayForm"))

);

const ScholarSessionAttSingleview = SuspenseWrapper(
  lazy(() => import("components/attendance/month-attendance/ScholarSessionSingleview"))
);


const ScholarAttendanceRoutes = {
  path: "attendance",
  element: <Outlet />,
  children: [
    {
      path: "reports",
      element: <Outlet />,
      children: [
        {
          path: "student-report",
          element: <AttStudentReport />,
        },
        {
          path: "consolidated-report",
          element: <AttConsolidatedReport />,
        },
      ],
    },
    {
      path: "status",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <AttendanceStatusList />,
        },
      ],
    },
    {
      path: "type-data",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <AttendanceTypeDataList />,
        },
      ],
    },
    {
      path: "master",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <AttendanceMasterList />,
        },
      ],
    },
    {
      path: "settings",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <AttendanceSettingsList />,
        },
      ],
    },
    {
      path: "list",
      element: <AttendanceManualEntryList />,
    },
    {
      path: "overview",
      element: <AttendanceOverView />,
    },
    {
      path: "view/:hash",
      element: <AttendanceManualEntryView />,
    },
    {
      path: "entry",
      element: <AttendanceManualEntryParams />,
    },
    {
      path: "entry/:hash",
      element: <AttendanceManualEntryEdit />,
    },
    {
      path: "month-attendance",
      element: <Outlet />,
      children: [
        {
          path: "scholarday-att",
          element: <ScholarDayMonthAtt />,
        },
        {
          path: "scholarsession-att",
          element: <ScholarSessionAtt />,
        },
        {
          path: "scholarperiod-att",
          element: <ScholarPeriodAtt />,
        },
        {
          path: "scholarsession-att-singleview",
          element: <ScholarSessionAttSingleview />,
        },
      ],
    },
    {
      path: "holiday",
      element: <Outlet />,
      children: [
        {
          path: "typelist",
          element: <AttendanceHolidayTypeList />,
        },
        {
          path: "list",
          element: <AttendanceHolidayList />,
        },
        {
          path: "add",
          element: <AttendaceHolidayForm />,
        },
        {
          path: "edit/:hash",
          element: <AttendaceHolidayForm />,
        },
      ],
    },
  ],
};

export default ScholarAttendanceRoutes;
