import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ClassList = SuspenseWrapper(lazy(() => import("components/mapping/ClassList")));
const ClassBulk = SuspenseWrapper(lazy(() => import("components/mapping/ClassBulk")));
const ClassForm = SuspenseWrapper(lazy(() => import("components/mapping/ClassForm")));
const ClassSectionList = SuspenseWrapper(
  lazy(() => import("components/mapping/ClassSectionList"))
);
const ClassSectionBulk = SuspenseWrapper(
  lazy(() => import("components/mapping/ClassSectionBulk"))
);
const ClassSecSubjectAcc = SuspenseWrapper(
  lazy(() => import("components/mapping/class-sec-subject/OverAllView"))
);
const StaffClassSubjectAcc = SuspenseWrapper(
  lazy(() => import("components/mapping/subject-staff-cls-sec/OverAllView"))
);
const ClassTeacherList = SuspenseWrapper(
  lazy(() => import("components/mapping/ClassTeacherList"))
);
const StaffSubjectList = SuspenseWrapper(
  lazy(() => import("components/mapping/StaffSubjectList"))
);
const StaffSubjectBulk = SuspenseWrapper(
  lazy(() => import("components/mapping/StaffSubjectBulk"))
);
const StaffDataPrivacyList = SuspenseWrapper(
  lazy(() => import("components/mapping/staff-data-privacy/OverAllView"))
);
const StaffDataPrivacyForm = SuspenseWrapper(
  lazy(() => import("components/mapping/staff-data-privacy/StaffDataPrivacyForm"))
);
const QuickAdd = SuspenseWrapper(
  lazy(() => import("components/mapping/subject-staff-cls-sec/QuickAdd"))
);
const StaffMapping = SuspenseWrapper(
  lazy(()=> import("components/mapping/StaffMapping"))
)
const MappingRoutes = {
  path: "mapping",
  element: <Outlet />,
  children: [
    {
      path: "class",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ClassList />,
        },
        {
          path: "add",
          element: <ClassForm />,
        },
        {
          path: "bulk-mapping",
          element: <ClassBulk />,
        },
        {
          path: "edit/:hash",
          element: <ClassForm />,
        },
      ],
    },
    {
      path: "class-section",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ClassSectionList />,
        },
        {
          path: "bulk-mapping",
          element: <ClassSectionBulk />,
        },
      ],
    },
    {
      path: "staff-subject",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffSubjectList />,
        },
        {
          path: "bulk-mapping",
          element: <StaffSubjectBulk />,
        },
      ],
    },
    {
      path: "class-sec-subject",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ClassSecSubjectAcc />,
        },
      ],
    },
    {
      path: "staff-class-subject",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffClassSubjectAcc />,
        },{
          path: "quick",
          element: <QuickAdd />,
        },
      ],
    },
    {
      path: "staff-data-privacy",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <StaffDataPrivacyList />,
        },
        {
          path: "add",
          element: <StaffDataPrivacyForm />,
        },
        {
          path: "edit",
          element: <StaffDataPrivacyForm />,
        },
      ],
    },
    {
      path: "class-teacher",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ClassTeacherList />,
        },
      ],
    },
    {
      path: "staff-mapping",
      element: <Outlet/>,
      children: [
        {
          path: "staff",
          element: <StaffMapping/>
        }
      ]

    }
  ],
};

export default MappingRoutes;
